import { default as _91slug_93cdf2dv6FbPMeta } from "/app/pages/blog/[slug].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as _91name_93FuYAqwYxehMeta } from "/app/pages/brands/[name].vue?macro=true";
import { default as indexooJswcUi1vMeta } from "/app/pages/brands/index.vue?macro=true";
import { default as indexAacQV8oPDMMeta } from "/app/pages/cart/index.vue?macro=true";
import { default as indexhypb6UNn8QMeta } from "/app/pages/catalog/[...slug]/index.vue?macro=true";
import { default as indexvJKqjSbZLzMeta } from "/app/pages/catalog/index.vue?macro=true";
import { default as index7ZtduOIoVXMeta } from "/app/pages/checkout/index.vue?macro=true";
import { default as indexKL1s9FFNEvMeta } from "/app/pages/favorites/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as _91_46_46_46slug_93kH4cSi8s9tMeta } from "/app/pages/info/[...slug].vue?macro=true";
import { default as indexgK5VicK7y2Meta } from "/app/pages/login/index.vue?macro=true";
import { default as maintenanceM1L3mPagESMeta } from "/app/pages/maintenance.vue?macro=true";
import { default as _91orderUri_93jvY8fhq1OIMeta } from "/app/pages/orders/[orderUri].vue?macro=true";
import { default as _91orderId_933QbgvpuyjnMeta } from "/app/pages/orders/confirmation/[orderId].vue?macro=true";
import { default as sending6Y4wycIsHgMeta } from "/app/pages/orders/sending.vue?macro=true";
import { default as page_45not_45found8L0MhHNDjFMeta } from "/app/pages/page-not-found.vue?macro=true";
import { default as addressUfPf91s3DVMeta } from "/app/pages/profile/index/address.vue?macro=true";
import { default as discountsT3wyU3jGxlMeta } from "/app/pages/profile/index/discounts.vue?macro=true";
import { default as infow8TnrWkOo7Meta } from "/app/pages/profile/index/info.vue?macro=true";
import { default as _91orderId_93h2RrcSWjhXMeta } from "/app/pages/profile/index/orders/[orderId].vue?macro=true";
import { default as indexi1HsinzcNpMeta } from "/app/pages/profile/index/orders/index.vue?macro=true";
import { default as reviews38SX2xFnk4Meta } from "/app/pages/profile/index/reviews.vue?macro=true";
import { default as indexaeouN7Kzh7Meta } from "/app/pages/profile/index.vue?macro=true";
import { default as indexHVbCWNFgttMeta } from "/app/pages/sale/index.vue?macro=true";
import { default as indexP0q8KYV1WMMeta } from "/app/pages/search/index.vue?macro=true";
import { default as indexBV1zPX3VR7Meta } from "/app/pages/sitemap/index.vue?macro=true";
import { default as _91slug_9387KzJmF8rIMeta } from "/app/pages/special-offers/[slug].vue?macro=true";
import { default as indexSKut4QXfKYMeta } from "/app/pages/special-offers/index.vue?macro=true";
import { default as component_45stubnO9MU04yTUMeta } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubnO9MU04yTU } from "/app/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    component: () => import("/app/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue")
  },
  {
    name: "brands-name",
    path: "/brands/:name()",
    component: () => import("/app/pages/brands/[name].vue")
  },
  {
    name: "brands",
    path: "/brands",
    meta: indexooJswcUi1vMeta || {},
    component: () => import("/app/pages/brands/index.vue")
  },
  {
    name: "cart",
    path: "/cart",
    meta: indexAacQV8oPDMMeta || {},
    component: () => import("/app/pages/cart/index.vue")
  },
  {
    name: "catalog-slug",
    path: "/catalog/:slug(.*)*",
    component: () => import("/app/pages/catalog/[...slug]/index.vue")
  },
  {
    name: "catalog",
    path: "/catalog",
    meta: indexvJKqjSbZLzMeta || {},
    component: () => import("/app/pages/catalog/index.vue")
  },
  {
    name: "checkout",
    path: "/checkout",
    component: () => import("/app/pages/checkout/index.vue")
  },
  {
    name: "favorites",
    path: "/favorites",
    component: () => import("/app/pages/favorites/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "info-slug",
    path: "/info/:slug(.*)*",
    component: () => import("/app/pages/info/[...slug].vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/app/pages/login/index.vue")
  },
  {
    name: "maintenance",
    path: "/maintenance",
    meta: maintenanceM1L3mPagESMeta || {},
    component: () => import("/app/pages/maintenance.vue")
  },
  {
    name: "orders-orderUri",
    path: "/orders/:orderUri()",
    component: () => import("/app/pages/orders/[orderUri].vue")
  },
  {
    name: "orders-confirmation-orderId",
    path: "/orders/confirmation/:orderId()",
    component: () => import("/app/pages/orders/confirmation/[orderId].vue")
  },
  {
    name: "orders-sending",
    path: "/orders/sending",
    component: () => import("/app/pages/orders/sending.vue")
  },
  {
    name: "page-not-found",
    path: "/page-not-found",
    component: () => import("/app/pages/page-not-found.vue")
  },
  {
    name: "profile",
    path: "/profile",
    component: () => import("/app/pages/profile/index.vue"),
    children: [
  {
    name: "profile-index-address",
    path: "address",
    component: () => import("/app/pages/profile/index/address.vue")
  },
  {
    name: "profile-index-discounts",
    path: "discounts",
    component: () => import("/app/pages/profile/index/discounts.vue")
  },
  {
    name: "profile-index-info",
    path: "info",
    component: () => import("/app/pages/profile/index/info.vue")
  },
  {
    name: "profile-index-orders-orderId",
    path: "orders/:orderId()",
    component: () => import("/app/pages/profile/index/orders/[orderId].vue")
  },
  {
    name: "profile-index-orders",
    path: "orders",
    component: () => import("/app/pages/profile/index/orders/index.vue")
  },
  {
    name: "profile-index-reviews",
    path: "reviews",
    component: () => import("/app/pages/profile/index/reviews.vue")
  }
]
  },
  {
    name: "sale",
    path: "/sale",
    component: () => import("/app/pages/sale/index.vue")
  },
  {
    name: "search",
    path: "/search",
    meta: indexP0q8KYV1WMMeta || {},
    component: () => import("/app/pages/search/index.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    meta: indexBV1zPX3VR7Meta || {},
    component: () => import("/app/pages/sitemap/index.vue")
  },
  {
    name: "special-offers-slug",
    path: "/special-offers/:slug()",
    component: () => import("/app/pages/special-offers/[slug].vue")
  },
  {
    name: "special-offers",
    path: "/special-offers",
    meta: indexSKut4QXfKYMeta || {},
    component: () => import("/app/pages/special-offers/index.vue")
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/news/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  },
  {
    name: component_45stubnO9MU04yTUMeta?.name,
    path: "/articles/:pathMatch(.*)",
    component: component_45stubnO9MU04yTU
  }
]