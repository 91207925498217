// eslint-disable-next-line no-unused-vars
export default defineNuxtRouteMiddleware(async (to, _from) => {
  if (to.path !== '/' && to.path.endsWith('/')) {
    const { path, query, hash } = to;
    const nextPath = path.replace(/\/+$/, '') || '/';
    const nextRoute = { path: nextPath, query, hash };
    return navigateTo(nextRoute, { redirectCode: 301 });
  }

  const { isMaintenance } = useRuntimeConfig().public;
  const allowedRoutes = ['/maintenance'];

  if (isMaintenance === 'true' && !allowedRoutes.includes(to.path)) {
    return navigateTo('/maintenance');
  } else if (isMaintenance === 'false' && to.path === '/maintenance') {
    return navigateTo('/');
  }
});
