<script lang="ts" setup>
const error = useError();
const is404 = computed(() => error.value?.statusCode === 404);

if (is404.value) {
  await navigateTo('/page-not-found', { replace: true });
}
</script>

<template>
  <div>
    <div class="flex items-center justify-center h-screen">
      <div class="flex flex-col gap-14 py-24 px-4 text-center">
        <h1 class="text-[96px] sm:text-[128px] font-black">{{ error?.statusCode }}</h1>

        <h2 class="text-2xl sm:text-[48px]">Произошла ошибка</h2>

        <p>{{ error?.message }}</p>

        <p>
          Если ошибка повторяется –
          <AppLink
            class="text-label-violet"
            to="/info/kontakty"
          >
            сообщите нам
          </AppLink>
          .
        </p>

        <div class="flex gap-5 sm:gap-10 flex-column sm:flex-row justify-center">
          <TheBtn
            color="outline"
            to="/"
          >
            На главную
          </TheBtn>

          <TheBtn
            color="outline"
            to="/catalog"
          >
            В каталог
          </TheBtn>
        </div>
      </div>
    </div>
  </div>
</template>
