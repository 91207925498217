import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirects_45global from "/app/middleware/redirects.global.ts";
import setup_45meta_45global from "/app/middleware/setup-meta.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirects_45global,
  setup_45meta_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}