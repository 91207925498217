import { VueReCaptcha } from 'vue-recaptcha-v3';

export default defineNuxtPlugin((nuxtApp) => {
  const { recaptchaKey } = useRuntimeConfig().public;

  nuxtApp.vueApp.use(VueReCaptcha, {
    siteKey: recaptchaKey,
    loaderOptions: { autoHideBadge: true },
  });
});
