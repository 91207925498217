<script lang="ts" setup>
defineEmits(['click']);

const props = defineProps<{
  to?: string;
  href?: string;
  target?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  prependIcon?: string;
  appendIcon?: string;
  color?: string;
  size?: string;
  disabled?: boolean;
  isLoading?: boolean;
}>();

const computedClasses = computed(() => [
  `the-btn--${props.size ?? 'lg'}`,
  `the-btn--${props.color ?? 'primary'}`,
  {
    'is-loading': props.isLoading,
    'the-btn--disabled': props.disabled,
  },
]);
</script>

<template>
  <AppLink
    v-if="to"
    :class="computedClasses"
    :target="target ?? '_self'"
    :to
    class="the-btn"
  >
    <component
      :is="`Svgo${prependIcon}`"
      v-if="prependIcon"
      class="the-btn__icon"
      filled
    />

    <slot />

    <component
      :is="`Svgo${appendIcon}`"
      v-if="appendIcon"
      class="btn__icon"
      filled
    />
  </AppLink>

  <a
    v-else-if="href"
    :class="computedClasses"
    :href
    :target="target ?? '_self'"
    class="the-btn"
  >
    <component
      :is="`Svgo${prependIcon}`"
      v-if="prependIcon"
      class="the-btn__icon"
      filled
    />

    <slot />

    <component
      :is="`Svgo${appendIcon}`"
      v-if="appendIcon"
      class="btn__icon"
      filled
    />
  </a>

  <button
    v-else
    :class="computedClasses"
    :disabled="disabled"
    :type="type ?? 'button'"
    class="the-btn"
    @click="$emit('click')"
  >
    <component
      :is="`Svgo${prependIcon}`"
      v-if="prependIcon"
      class="the-btn__icon"
      filled
    />

    <slot />

    <component
      :is="`Svgo${appendIcon}`"
      v-if="appendIcon"
      class="the-btn__icon"
      filled
    />
  </button>
</template>

<style lang="scss" scoped>
.the-btn {
  display: flex;
  gap: 0.75rem;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  border-radius: 12px;
  font-weight: $bold;
  text-align: center;
  transition: opacity 0.3s;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 0.7;
  }

  &--primary {
    background-color: $hell-black;
    color: $white;

    &.is-loading {
      background: $hell-black-80
        linear-gradient(
          -45deg,
          $hell-black 25%,
          transparent 25%,
          transparent 50%,
          $hell-black 50%,
          $hell-black 75%,
          transparent 75%,
          transparent
        );
      background-size: 50px 50px;
      cursor: wait !important;
      pointer-events: none;
      animation: load 2s linear infinite;
    }
  }

  &--secondary {
    background-color: $hell-black-10;
    color: $hell-black;

    &.is-loading {
      background: $hell-black-20
        linear-gradient(
          -45deg,
          $hell-black-40 25%,
          transparent 25%,
          transparent 50%,
          $hell-black-40 50%,
          $hell-black-40 75%,
          transparent 75%,
          transparent
        );
      background-size: 50px 50px;
      cursor: wait;
      pointer-events: none;
      animation: load 2s linear infinite;
    }
  }

  &--outline {
    border: 1px solid $hell-black;
    background-color: transparent;
    color: $hell-black;

    &.is-loading {
      border-color: $hell-black-60;
      background: $hell-black-10
        linear-gradient(
          -45deg,
          $hell-black-20 25%,
          transparent 25%,
          transparent 50%,
          $hell-black-20 50%,
          $hell-black-20 75%,
          transparent 75%,
          transparent
        );
      background-size: 50px 50px;
      color: $hell-black-80;
      cursor: wait !important;
      pointer-events: none;
      animation: load 2s linear infinite;
    }
  }

  &--green {
    background-color: $hell-green;
    color: $white;

    &.is-loading {
      background: rgb(0 135 58 / 75%)
        linear-gradient(
          -45deg,
          $hell-green 25%,
          transparent 25%,
          transparent 50%,
          $hell-green 50%,
          $hell-green 75%,
          transparent 75%,
          transparent
        );
      background-size: 50px 50px;
      cursor: wait !important;
      pointer-events: none;
      animation: load 2s linear infinite;
    }
  }

  &--lg {
    height: 48px;
  }

  &--md {
    height: 40px;
    font-size: $text-sm;
  }

  &--sm {
    height: 28px;
    border-radius: 8px;
    font-size: $text-xs;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &:disabled,
  &--disabled {
    background-color: $hell-black-10;
    color: $hell-black-60;
    cursor: not-allowed;
    pointer-events: none;
  }
}

@keyframes load {
  0% {
    background-position: 0 0;
  }

  100% {
    background-position: 50px 50px;
  }
}
</style>
