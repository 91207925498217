import type { PluginOptions } from 'vue-toastification';
import Toast, { POSITION } from 'vue-toastification';

const options: PluginOptions = {
  closeButton: 'button',
  closeOnClick: true,
  containerClassName: '!mb-6 sm:!mb-2',
  toastClassName: '!py-3',
  draggable: true,
  draggablePercent: 0.6,
  hideProgressBar: false,
  icon: true,
  maxToasts: 3,
  newestOnTop: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  position: POSITION.BOTTOM_CENTER,
  rtl: false,
  showCloseButtonOnHover: true,
  timeout: 2000,
  transition: 'Vue-Toastification__fade',
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, options);
});
