// eslint-disable-next-line no-unused-vars
export default defineNuxtRouteMiddleware(async (to, _from) => {
  const { appUrl } = useRuntimeConfig().public;

  useHead({
    link: [
      {
        rel: 'canonical',
        href: appUrl + to.path,
      },
    ],
  });
});
